import { ApiService } from "./ApiService/ApiService";
import { urlConfig } from "../config/urlConfig";
import {
  signupFailure,
  signupRequest,
  signupSuccess,
} from "../store/sample/action";

export const sendOtp = (mobile) => {
  return ApiService.post(urlConfig.auth.sendOtp, { mobile });
};

// export const verifyOtp = (otp, otp_token) => {
//   return ApiService.post(urlConfig.auth.verifyOtp, { otp, otp_token });
// };
export const resendOtp = (otp_token) => {
  return ApiService.post(urlConfig.auth.resendOtp, {
    otp_token,
  });
};
export const signup = (payload) => {
  return ApiService.post(urlConfig.auth.signup, payload);
};
// export const google_login = (payload) => {
//   return ApiService.post(urlConfig.auth.google_login, payload);
// };

// export const verifyOtp = (otp, otp_token) => {
//   console.log(otp, otp_token, "otp, otp_token");
//   return async (dispatch) => {
//     dispatch(signupRequest());
//     try {
//       const response = await ApiService.post(urlConfig.auth.verifyOtp, {
//         otp,
//         otp_token,
//       });
//       console.log(response, "red",urlConfig);
//       dispatch(signupSuccess(response.data));
//       return response;
//     } catch (error) {
//       dispatch(signupFailure(error));
//     }
//   };
// };

export const verifyOtp = (otp, otp_token) => {
  return async (dispatch) => {
    dispatch(signupRequest());

    try {
      // Attempting to post the OTP and token to the server
      const response = await ApiService.post(urlConfig.auth.verifyOtp, {
        otp,
        otp_token,
      });

      // Dispatch success action with the response data
      dispatch(signupSuccess(response.data));

      return response;
    } catch (error) {
      // Detailed error logging for better debugging
      try {
        if (error.response) {
          // When a response was received from the server but with an error code
          console.error("Server responded with an error:");
          console.error("Status Code:", error.response.status);
          console.error(
            "Response Data:",
            JSON.stringify(error.response.data?.message, null, 2)
          );
          dispatch(signupFailure(error.response?.data));
        } else if (error.request) {
          // When the request was made but no response received
          console.error("No response received for the request:", error.request);
        } else {
          // Any other error during the request setup
          console.error("Error during request setup:", error.message);
        }
      } catch (e) {
        // Error while trying to log the initial error object
        console.error("Error while processing the error object:", e);
      }

      // Dispatch failure action with the error object
      // dispatch(signupFailure(error));
    }
  };
};

export const google_login = (payload) => {
  return async (dispatch) => {
    dispatch(signupRequest());
    try {
      const response = await ApiService.post(
        urlConfig.auth.google_login,
        payload
      );
      dispatch(signupSuccess(response.data));
      return response;
    } catch (error) {
      dispatch(signupFailure(error));
    }
  };
};

// export const switchtoproducer = (payload) => {
//   return ApiService.post(urlConfig.auth.switchtoproducer, payload);
// };
export const switchtoproducer = (payload) => {
  return async (dispatch) => {
    try {
      const response = await ApiService.post(
        urlConfig.auth.switchtoproducer,
        payload
      );
      return response;
    } catch (error) {}
  };
};
