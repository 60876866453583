import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Box,
  Tabs,
  Tab,
  Typography,
  Button,
  TextField,
  Avatar,
  Backdrop,
  CircularProgress,
  Drawer,
  FormLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../scss/Account.scss";
import WatchHistory from "./WatchHistory";
import Transactions from "./transactionsData";
import MembershipPlans from "./MembershipPlans";
import { useNavigate, useParams } from "react-router-dom";
import { getprofileDetailS, updateProfile } from "../../services/account";
import { toast } from "react-hot-toast";
import YourUploads from "./yourUploads";
import { uploadImageApi } from "../../services/movieupload";
import { useDispatch, useSelector } from "react-redux";
import AppsIcon from "@mui/icons-material/Apps";
import ProducerUpdate from "../Account/ProducerUpdate";

const Account = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [role, setRole] = useState("");
  const { type } = useParams();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    profile_pic: "",
  });
  const [initialState, setInitialState] = useState({
    name: "",
    email: "",
    mobile: "",
    profile_pic: "",
  });

  const validateProfile = () => {
    const newErrors = {};

    if (!state.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (
      !state.email.trim() ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)
    ) {
      newErrors.email = "Email is required";
    }

    if (!state.mobile.trim()) {
      newErrors.mobile = "Mobile  is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fileInputRef = useRef(null);

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  useEffect(() => {
    const userRole = localStorage.getItem("role");
    setRole(userRole);
    if (userRole === "producer") {
      setTabIndex(0);
    }
    setTabIndex(parseInt(type));
  }, [type]);

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getprofileDetailS());
      if (response?.data?.status) {
        setState({
          ...response?.data?.data?.user,
        });
        setInitialState({
          ...response?.data?.data?.user,
        });
      }
    }
    fetchData();
  }, [Loading]);

  const handleTabChange = (newValue) => {
    setTabIndex(newValue);
    navigate(`/mainpage/${newValue}`);
  };
  const handleEdit = async () => {
    setLoading(true);
    if (!validateProfile()) {
      return;
    }
    let payload = { ...state };
    try {
      const response = await updateProfile(payload);
      if (response?.data?.status) {
        toast.success("Profile updated successfully");
        const data = await getprofileDetailS();
        if (data?.data?.status) {
          const userData = data?.data?.data?.user;
          setState({ ...userData });
          setInitialState({ ...userData });
          localStorage.removeItem("user_name");
          localStorage.removeItem("profile_pic");
          localStorage.removeItem("userData");
          localStorage.removeItem("mobile");
          localStorage.setItem("user_name", userData?.name || "");
          localStorage.setItem("profile_pic", userData?.profile_pic || "");
          localStorage.setItem(
            "userData",
            JSON.stringify(data?.data?.data?.user)
          );
          localStorage.setItem("mobile", userData?.mobile);
        }
      } else {
        console.log("Update profile response:", response);
      }
    } catch (error) {
      console.error("An error occurred while updating the profile:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const uploadResponse = await uploadImageApi(formData);
        if (uploadResponse.status === 200) {
          setState({
            ...state,
            profile_pic: uploadResponse.data.data.filePath,
          });
          toast.success("Image uploaded successfully.");
        } else {
          toast.error("Failed to upload image. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while uploading the image.");
      }
    }
  };

  // Function to trigger file input click
  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const hasChanges = Object.keys(state).some(
    (key) => state[key] !== initialState[key]
  );
  return (
    <>
      <Container maxWidth={false} className="account-container">
        <Box className="account-header">
          <div>
            <Typography
              variant="h4"
              component="div"
              className="account-title"
              sx={{
                fontWeight: "700",
                lineHeight: "41.66px",
                textAlign: "left",
                fontSize: {
                  xs: "24px", // Hide on extra-small screens
                  sm: "24px", // Hide on small screens
                  md: "32px", // Show on medium screens and larger
                },
              }}
            >
              Account
            </Typography>
            <Typography variant="subtitle1" component="div">
              Manage Your Account Settings
            </Typography>
          </div>
          <Button
            className="mobile"
            onClick={() => {
              toggleDrawer();
            }}
          >
            <AppsIcon
              style={{
                color: "#E6E6E6",
              }}
            />
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} sm={12} className="account-tabs-grid">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tabIndex}
              className="account-tabs"
              TabIndicatorProps={{ className: "hidden-indicator" }}
            >
              <Tab
                icon={<ArrowForwardIosIcon />}
                onClick={() => {
                  handleTabChange(0);
                }}
                iconPosition="end"
                label="Profile"
                value={0}
              />
              {role === "producer" && (
                <Tab
                  icon={<ArrowForwardIosIcon />}
                  onClick={() => {
                    handleTabChange(5);
                  }}
                  value={5}
                  disabled={user?.approval !== "approve"}
                  iconPosition="end"
                  label="Your Uploads"
                  className="custom-tab"

                />
              )}
              {/* <Tab
                icon={<ArrowForwardIosIcon />}
                onClick={() => {
                  handleTabChange(1);
                }}
                value={1}
                iconPosition="end"
                label="Watch History"
              /> */}
              <Tab
                icon={<ArrowForwardIosIcon />}
                onClick={() => {
                  handleTabChange(2);
                }}
                iconPosition="end"
                label="Transactions"
                value={2}
              />
              <Tab
                icon={<ArrowForwardIosIcon />}
                onClick={() => {
                  handleTabChange(3);
                }}
                iconPosition="end"
                label="Choose Plan"
                value={3}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10} sm={12}>
            <div>
              {tabIndex === 0 && (
                <Box className="profile-tab-content">
                  <Typography variant="h5" component="div" gutterBottom>
                    Profile
                  </Typography>
                  <Typography variant="subtitle1" component="div" gutterBottom>
                    Update Your Personal Information
                  </Typography>
                  <Box className="profile-form">
                    <Typography
                      variant="subtitle2"
                      component="div"
                      gutterBottom
                    >
                      Personal Information
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Note: Document accept only JPG, PNG file format and should
                      be less than 10MB.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 2,
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Avatar
                        sx={{ width: 100, height: 100, marginRight: 2 }}
                        src={state?.profile_pic}
                      />
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                      <Button
                        style={{ color: "#FED530" }}
                        component="span"
                        startIcon={<CameraAltIcon />}
                        onClick={handleImageUpload}
                      >
                        Upload Display Picture
                      </Button>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {/* <FormLabel>Name</FormLabel> */}
                        <TextField
                          fullWidth
                          margin="normal"
                          placeholder="Name"
                          variant="outlined"
                          className="custom-textfield"
                          name={"name"}
                          onChange={(e) => {
                            handleChange("name", e.target.value);
                          }}
                          value={state?.name}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <FormLabel>Name</FormLabel> */}
                        <TextField
                          fullWidth
                          margin="normal"
                          placeholder="Email"
                          variant="outlined"
                          className="custom-textfield"
                          name="email"
                          onChange={(e) => {
                            handleChange("email", e.target.value);
                          }}
                          value={state?.email}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {/* <FormLabel>Name</FormLabel> */}
                        <TextField
                          fullWidth
                          margin="normal"
                          placeholder="Phone Number"
                          variant="outlined"
                          className="custom-textfield"
                          name="mobile"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d{0,10}$/.test(value)) {
                              handleChange("mobile", value);
                            }
                          }}
                          value={state?.mobile}
                          helperText={errors.mobile}
                          error={!!errors.mobile}
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ textAlign: "left", marginTop: 2 }}>
                      <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={handleEdit}
                        style={{
                          backgroundColor: hasChanges ? "#FED530" : "gray",
                          color: "black",
                          cursor: "pointer",
                        }}
                        disabled={!hasChanges}
                      >
                        {!hasChanges ? "Edit" : "Save"}
                      </Button>
                    </Box>
                  </Box>
                  {role === "producer" && <ProducerUpdate />}
                </Box>
              )}

              {role !== "producer" ? (
                <>
                  {tabIndex === 1 && <WatchHistory />}
                  {tabIndex === 2 && <Transactions />}
                  {tabIndex === 3 && <MembershipPlans />}
                </>
              ) : (
                <>
                  {/* {tabIndex === 1 && <MovieUploadComponent />} */}
                  {tabIndex === 5 && <YourUploads />}

                  {tabIndex === 1 && <WatchHistory />}
                  {tabIndex === 2 && <Transactions />}
                  {tabIndex === 3 && <MembershipPlans />}
                </>
              )}
            </div>
          </Grid>
        </Grid>
        <Backdrop
          open={Loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>{" "}
      </Container>
      <Drawer
        open={open}
        anchor="right"
        sx={{
          ".MuiPaper-root": {
            background: "#000 !important",
            color: "#fff",
          },
        }}
        onClose={() => {
          toggleDrawer();
        }}
      >
        <Box
          sx={{
            margin: "1rem",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            className="account-tabs"
            TabIndicatorProps={{ className: "hidden-indicator" }}
          >
            <Tab
              icon={<ArrowForwardIosIcon />}
              onClick={() => {
                handleTabChange(0);
              }}
              iconPosition="end"
              label="Profile"
              value={0}
            />
            {role === "producer" && (
              <Tab
                icon={<ArrowForwardIosIcon />}
                onClick={() => {
                  handleTabChange(5);
                }}
                value={5}
                iconPosition="end"
                label="Your Uploads"
              />
            )}
            {/* <Tab
              icon={<ArrowForwardIosIcon />}
              onClick={() => {
                handleTabChange(1);
              }}
              value={1}
              iconPosition="end"
              label="Watch History"
            /> */}
            <Tab
              icon={<ArrowForwardIosIcon />}
              onClick={() => {
                handleTabChange(2);
              }}
              iconPosition="end"
              label="Transactions"
              value={2}
            />
            <Tab
              icon={<ArrowForwardIosIcon />}
              onClick={() => {
                handleTabChange(3);
              }}
              iconPosition="end"
              label="Choose Plan"
              value={3}
            />
          </Tabs>
        </Box>
      </Drawer>
    </>
  );
};

export default Account;
