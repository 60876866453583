// src/components/CrossfadeCarousel.jsx
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/CrossfadeCarousel.scss";
import Button from "react-bootstrap/Button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { showPlayButton } from "../HomePage/showthePlayButton";
// import play from "../../images/moredetails/play.svg";
import { PlayCircle } from "@mui/icons-material";
import forword from "../../images/Icons/circleforword.svg";
import backword from "../../images/Icons/circlebackword.svg";
import { Typography } from "@mui/material";

const CrossfadeCarousel = ({ data }) => {
  const banners = data.banners || [];

  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + banners.length) % banners.length);
  };

  const handleMoreDetailsClick = (id) => {
    navigate(`/moredetails/${id}`, { state: { banners } });
  };

  const isAuthenticated = localStorage.getItem("isAuthenticated");

  return (
    <div className="mainbacks">
      <Carousel
        fade
        activeIndex={index}
        onSelect={handleSelect}
        className="custom-carousel"
        interval={6000}
        pause={true}
        slide={true}
        controls={false}
        autoPlay={true}
        infinite={true}
      >
        {banners?.map((item, index) => (
          <Carousel.Item key={index}>
            <img className="d-block" src={item?.cover_pic} alt={item.title} />
            <Carousel.Caption
              className="custom-caption"
              style={{ marginLeft: "3rem" }}
            >
              <Typography
                variant="h3"
                sx={{
                  marginTop: "10px",
                  color: "white",
                  fontFamily: "DM Sans",
                  fontWeight: "700",
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "0",
                  fontSize: {
                    xs: "24px",
                    sm: "30px",
                    md: "38px",
                    lg: "38px",
                  },
                }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  marginTop: "10px",
                  color: "#E6E6E6",
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontWeight: "400",
                  display: { xs: "none", md: "block", sm: "none" },
                  textAlign: "start",
                  marginBottom: "0",
                }}
              >
                {item.description}
              </Typography>
              <br/>
              <div className="carousel-button">
                <Button
                  variant="secondary"
                  className="buybutton"
                  onClick={() =>
                    isAuthenticated === "true"
                      ? handleMoreDetailsClick(item.id)
                      : navigate("/login")
                  }
                >
                  {item?.is_buied ? (
                    <>
                      {" "}
                      <PlayCircle /> Play Now{" "}
                    </>
                  ) : item?.is_subscribed ? (
                    <>
                      {" "}
                      <PlayCircle /> Play Now{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <LockOutlinedIcon /> Buy/Rent{" "}
                    </>
                  )}
                </Button>
                <Button
                  variant="secondary"
                  className="morebutton"
                  onClick={() => handleMoreDetailsClick(item.id)}
                >
                  <InfoOutlinedIcon /> More Details
                </Button>
              </div>
            </Carousel.Caption>
            <div className="custom-controls">
              <button onClick={handlePrev} className="custom-control-prev">
                <img
                  src={forword}
                  alt="icon"
                  className="custom-controls-image "
                />
              </button>
              <button onClick={handleNext} className="custom-control-next">
                <img
                  src={backword}
                  alt="icon"
                  height={40}
                  className="custom-controls-image "
                />
              </button>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CrossfadeCarousel;
