import React, { useCallback, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../scss/MoreDetails.scss";
import Button from "react-bootstrap/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import play from "../../images/moredetails/play.svg";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Banner1 from "../../images/MovieBanner/Uc.jpg";
import {
  homeview,
  createOrderForrentMovie,
  rentMovie,
} from "../../services/home";
import Spinner from "react-bootstrap/Spinner";
import useRazorpay from "react-razorpay";
import { showPlayButton } from "../HomePage/showthePlayButton";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Tooltip, Typography } from "@mui/material";
import SharePopUpBox from "../../common/sharePopUp/SharePopUp";
import { BookmarkAdded, BookmarkAddOutlined } from "@mui/icons-material";
import TicketConfirmed from "../../common/rating/TicketConfirmed";
import BuyRentModal from "../../common/BuyRentModal/BuyRentModal";

export default function MoreDetailsHeader({
  id,
  trailerWatchHandler,
  onAddToWatchlist,
}) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const location = useLocation();
  const domainName = window.location.hostname;
  console.log(location, "Location");
  const [value, setValue] = useState([]);
  const [firstShowData, setFirstShowData] = useState({});
  const [isDoingPayement, setIsDoingPayement] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const userDataString = localStorage.getItem("userData");
  const userData = JSON.parse(userDataString);
  const [Razorpay, isLoaded] = useRazorpay();
  const navigate = useNavigate();

  const ticketConfirmHandler = (movie) => {
    if (movie?.published) {
      setOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (id) {
      moredeatilsview(id);
    }
  }, [id]);

  const handlePayment = useCallback(
    async (id, movie) => {
      if (movie?.movie_plan === "first_show") {
        setIsDoingPayement(true);
        const response = await createOrderForrentMovie({
          movie_id: id,
        });
        if (response?.data?.status) {
          const { order } = response?.data?.data;
          try {
            const options = {
              amount: order.amount,
              key: "rzp_live_2Vh6kOtmT9HUYY",
              currency: order?.currency,
              name: userData?.name,
              description: "",
              image: "",
              order_id: order.id,
              handler: (res) => {
                Rentmovie(res, id, movie);
              },
              prefill: {
                name: userData?.name,
                email: userData?.email,
                contact: userData?.mobile,
              },
              notes: {
                address: "Zerozilla Corporate Office",
              },
              theme: {
                color: "#3399cc",
              },
            };
            const rzpay = new Razorpay(options);
            rzpay.open();
          } catch (error) {
            console.error("Payment error:", error);
          }
        }
        setIsDoingPayement(false);
        setShowModal(false);
      } else {
        navigate(`/mainpage/${3}`);
      }

      if (!isLoaded) return;
    },
    [isLoaded, Razorpay]
  );
  useEffect(() => {}, [handlePayment]);

  const moredeatilsview = useCallback(async (id) => {
    setLoading(true);
    try {
      const response = await homeview(id);
      if (response && response.data) {
        setValue(response.data?.data?.movie);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setLoading(false);
  }, []);

  const handleAddToWatchlist = async (movieId) => {
    await onAddToWatchlist(movieId);
    moredeatilsview(id);
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const convertSecondsToHrMin = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return hours > 0 ? `${hours} Hr ${minutes} Min` : `${minutes} Min`;
  };

  const Rentmovie = useCallback(
    async (data, id, movie) => {
      const payload = {
        movie_id: id,
        razorpay_payment_id: data?.razorpay_payment_id,
        razorpay_order_id: data?.razorpay_order_id,
        razorpay_signature: data?.razorpay_signature,
      };
      if (movie?.movie_plan === "first_show") {
        try {
          const response = await rentMovie(payload);
          if (response.data?.status === true) {
            ticketConfirmHandler(movie);

            setFirstShowData(response?.data?.data);
          }
        } catch (error) {
          console.error("Error renting movie:", error);
        }
      }
    },
    [value, moredeatilsview]
  );

  const [shareDetials, setShareDetials] = useState(false);
  const [shareMovie, setShareMovie] = useState("");

  const OpenSharePopUp = (item) => {
    setShareMovie(item?.id);
    setShareDetials(true);
  };

  const CloseSharePopUp = () => {
    setShareDetials(false);
  };
  const ticketConfirmHandlerclose = () => {
    setOpen((prevState) => !prevState);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    if (isAuthenticated === "true") {
      setShowModal(true); // Open modal instead of handling payment directly
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <TicketConfirmed
        selectedMovie={id}
        firstShowData={firstShowData}
        open={open}
        ticketConfirmHandler={ticketConfirmHandlerclose}
      />
      <div className="main-back">
        <div className="detail-container">
          {loading ? (
            <div className="loader">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : (
            <div className="image-section">
              <>
                <img
                  className="d-block"
                  style={{
                    // height: "600px",
                    width: "100%",
                    background:
                      "linearGradient(180deg, rgba(0, 0, 0, 0.1) 0%, #000000 68.75%)",
                  }}
                  src={isValidUrl(value.cover_pic) ? value?.cover_pic : Banner1}
                  alt={value.title || "Default image"}
                />
                <div className="overlay">
                  <div className="movie-info">
                    <span className="rating">
                      {value?.Certificate?.name === "U/A certificate"
                        ? "U/A +18"
                        : value?.Certificate?.name}
                    </span>
                    <Typography
                      variant="h3"
                      sx={{
                        marginTop: "10px",
                        color: "white",
                        fontFamily: "DM Sans",
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "start",
                        marginBottom: "0",
                        fontSize: {
                          xs: "20px",
                          sm: "20px",
                          md: "38px",
                          lg: "38px",
                        },
                      }}
                    >
                      {value.title}
                    </Typography>{" "}
                    <Typography
                      sx={{
                        marginTop: "10px",
                        color: "#E6E6E6",
                        fontFamily: "DM Sans",
                        fontSize: "16px",
                        fontWeight: "400",
                        display: { xs: "none", md: "block", sm: "none" },
                        textAlign: "start",
                      }}
                    >
                      {value.descriTypographytion}
                    </Typography>
                    <p>
                      <AccessTimeOutlinedIcon />{" "}
                      {convertSecondsToHrMin(value.duration)} &middot;{" "}
                      {value?.Genre?.name} &middot; ⭐ {value?.rating}
                    </p>
                    <div className="Butons-moredetails">
                      <BuyRentModal
                        value={value}
                        handlePayment={() => {
                          handlePayment(value?.id, value);
                        }}
                        show={showModal}
                        handleClose={() => setShowModal(false)}
                        isDoingPayement={isDoingPayement}
                      />
                      {value?.published || !value?.is_buied ? (
                        <>
                          {!showPlayButton(value) ? (
                            <Button
                              variant="warning"
                              className="ticket-button"
                              // disabled={isDoingPayement}
                              onClick={() => {
                                // setIsDoingPayement(true);
                                isAuthenticated === "true"
                                  ? handleOpenModal() // handlePayment(value?.id, value)
                                  : navigate("/login");
                              }}
                            >
                              {value?.movie_plan === "first_show"
                                ? `${
                                    // isDoingPayement
                                    //   ? "Please wait..."
                                    //   :
                                    `Get Ticket ₹ ${value?.price}`
                                  }`
                                : "Choose Plan"}
                            </Button>
                          ) : (
                            <Button
                              variant="warning"
                              className="ticket-button"
                              onClick={() => {
                                navigate(`/movie/streaming/${value?.id}`);
                              }}
                            >
                              <img
                                src={play}
                                alt="play"
                                style={{ height: "40px", width: "40px" }}
                              />
                              Watch Now
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          variant="warning"
                          className="ticket-button"
                          disabled
                        >
                          Coming Soon
                        </Button>
                      )}
                      <div className="icons" style={{ marginLeft: "10px" }}>
                        <Tooltip title="Watch List" placement="top">
                          <Button
                            variant="link"
                            className="icon-button"
                            onClick={() => handleAddToWatchlist(value?.id)}
                          >
                            {value?.is_saved ? (
                              <BookmarkAdded />
                            ) : (
                              <BookmarkAddOutlined />
                            )}
                          </Button>
                        </Tooltip>
                        <Tooltip title="Share" placement="top">
                          <Button
                            variant="link"
                            className="icon-button"
                            onClick={() => OpenSharePopUp(value)}
                          >
                            <ShareOutlinedIcon />
                          </Button>
                        </Tooltip>
                        {value?.Trailer && (
                          <Tooltip title="Watch Trailer" placement="top">
                            <Button
                              variant="link"
                              className="icon-button"
                              onClick={trailerWatchHandler}
                            >
                              <PlayArrowIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          )}
        </div>
        <SharePopUpBox
          open={shareDetials}
          onClose={CloseSharePopUp}
          data={shareMovie}
        />
      </div>
    </>
  );
}
