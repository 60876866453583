
// import React, { useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
// import { Grid, Typography, FormControl, Button } from "@mui/material";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import moment from "moment/moment";

// const ResponsiveDatePickers = ({ onChange }) => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [selectedTime, setSelectedTime] = useState(null);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     const formattedDate = moment(date).format("YYYY-MM-DD");
//     onChange("schedule_date", formattedDate);
//   };

//   const handleTimeChange = (time) => {
//     setSelectedTime(time);
//     const formattedTime = time
//       ? time.toLocaleTimeString([], {
//           hour: "2-digit",
//           minute: "2-digit",
//           hour12: false,
//         })
//       : "";
//     onChange("schedule_time", formattedTime);
//   };

//   const clearDateTime = () => {
//     setSelectedDate(null);
//     setSelectedTime(null);
//     onChange("schedule_date", "");
//     onChange("schedule_time", "");
//   };

//   // Determine if the selected date is today
//   const isToday = selectedDate ? moment(selectedDate).isSame(new Date(), "day") : false;

//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={5}>
//         <Typography variant="subtitle1" style={{ color: "#fff" }}>
//           Date
//         </Typography>

//         <FormControl fullWidth variant="outlined">
//           <DatePicker
//             selected={selectedDate}
//             onChange={handleDateChange}
//             dateFormat="MMMM d, yyyy"
//             minDate={new Date()}
//             customInput={
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   backgroundColor: "#000",
//                   borderRadius: "10px",
//                   padding: "8px",
//                   border: "2px solid gray",
//                   marginBottom: "20px",
//                 }}
//               >
//                 <CalendarTodayIcon style={{ color: "#fff", marginRight: "8px" }} />
//                 <input
//                   type="text"
//                   readOnly
//                   placeholder="Select Date"
//                   value={selectedDate ? selectedDate.toLocaleDateString() : ""}
//                   style={{
//                     flex: 1,
//                     border: "none",
//                     background: "black",
//                     color: "white",
//                     outline: "none",
//                     textAlign: "left",
//                     height: "45px",
//                     padding: "8px",
//                     borderRadius: "10px",
//                   }}
//                 />
//               </div>
//             }
//           />
//         </FormControl>
//       </Grid>
//       <Grid item xs={5}>
//         <Typography variant="subtitle1" style={{ color: "#fff" }}>
//           Time
//         </Typography>

//         <FormControl fullWidth variant="outlined">
//           <DatePicker
//             selected={selectedTime}
//             onChange={handleTimeChange}
//             showTimeSelect
//             showTimeSelectOnly
//             timeIntervals={5}
//             timeCaption="Time"
//             dateFormat="h:mm aa"
//             // Conditionally set minTime and maxTime based on whether the selected date is today
//             {...(isToday && {
//               minTime: new Date(),
//               maxTime: new Date().setHours(23, 59),
//             })}
//             customInput={
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   backgroundColor: "black",
//                   borderRadius: "10px",
//                   padding: "8px",
//                   border: "2px solid gray",
//                   marginBottom: "20px",
//                 }}
//               >
//                 <AccessTimeIcon style={{ color: "#fff", marginRight: "8px" }} />
//                 <input
//                   type="text"
//                   placeholder="Select Time"
//                   readOnly
//                   value={
//                     selectedTime
//                       ? selectedTime.toLocaleTimeString([], {
//                           hour: "2-digit",
//                           minute: "2-digit",
//                         })
//                       : ""
//                   }
//                   style={{
//                     flex: 1,
//                     border: "none",
//                     background: "black",
//                     color: "white",
//                     outline: "none",
//                     textAlign: "left",
//                     height: "45px",
//                     borderRadius: "10px",
//                   }}
//                 />
//               </div>
//             }
//           />
//         </FormControl>
//       </Grid>
//       <Grid item xs={2}>
//         <Button
//           variant="contained"
//           color="secondary"
//           onClick={clearDateTime}
//           sx={{
//             backgroundColor: "rgb(254, 213, 48)",
//             borderRadius: "10px",
//             color: "#000",
//             fontWeight: "550",
//             fontFamily: "sans-serif",
//             fontSize: "16px",
//             padding: "16px",
//             mt: 4,
//             "&:hover": {
//               backgroundColor: "rgb(255, 235, 59)",
//             },
//           }}
//         >
//           Clear Date & Time
//         </Button>
//       </Grid>
//       <Grid item xs={12}>
//         <div style={{ color: "#fff" }}>
//           <p style={{ textAlign: "left" }}>
//             <span>{selectedDate ? ` ${moment(selectedDate).format("ddd, D MMM")}, ` : ""}</span>{" "}
//             <span>{selectedTime ? `${moment(selectedTime).format("hh:mm A")}, ` : ""}</span>{" "}
//             India Standard Time, based on your location
//           </p>
//         </div>
//       </Grid>
//     </Grid>
//   );
// };

// export default ResponsiveDatePickers;
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { Grid, Typography, FormControl, Button } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment/moment";

const ResponsiveDatePickers = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [advanceBookingDate, setAdvanceBookingDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onChange("schedule_date", formattedDate);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
    const formattedTime = time
      ? time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      : "";
    onChange("schedule_time", formattedTime);
  };

  const handleAdvanceBookingChange = (date) => {
    setAdvanceBookingDate(date);
    const formattedDate = moment(date).format("YYYY-MM-DD");
    onChange("advance_booking_date", formattedDate);
  };

  const clearDateTime = () => {
    setSelectedDate(null);
    setSelectedTime(null);
    setAdvanceBookingDate(null);
    onChange("schedule_date", "");
    onChange("schedule_time", "");
    onChange("advance_booking_date", "");
  };

  const isToday = selectedDate ? moment(selectedDate).isSame(new Date(), "day") : false;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Date
        </Typography>
        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#000",
                  borderRadius: "10px",
                  padding: "8px",
                  border: "2px solid gray",
                  marginBottom: "20px",
                }}
              >
                <CalendarTodayIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  readOnly
                  placeholder="Select Date"
                  value={selectedDate ? selectedDate.toLocaleDateString() : ""}
                  style={{
                    flex: 1,
                    border: "none",
                    background: "black",
                    color: "white",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Time
        </Typography>
        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={selectedTime}
            onChange={handleTimeChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="h:mm aa"
            {...(isToday && {
              minTime: new Date(),
              maxTime: new Date().setHours(23, 59),
            })}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "black",
                  borderRadius: "10px",
                  padding: "8px",
                  border: "2px solid gray",
                  marginBottom: "20px",
                }}
              >
                <AccessTimeIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  placeholder="Select Time"
                  readOnly
                  value={
                    selectedTime
                      ? selectedTime.toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      : ""
                  }
                  style={{
                    flex: 1,
                    border: "none",
                    background: "black",
                    color: "white",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1" style={{ color: "#fff" }}>
          Advance Booking
        </Typography>
        <FormControl fullWidth variant="outlined">
          <DatePicker
            selected={advanceBookingDate}
            onChange={handleAdvanceBookingChange}
            dateFormat="MMMM d, yyyy"
            minDate={new Date()}
            maxDate={selectedDate}
            disabled={!selectedDate}
            customInput={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "black",
                  borderRadius: "10px",
                  padding: "8px",
                  border: "2px solid gray",
                  marginBottom: "20px",
                }}
              >
                <CalendarTodayIcon style={{ color: "#fff", marginRight: "8px" }} />
                <input
                  type="text"
                  readOnly
                  placeholder="Select Advance Booking Date"
                  value={advanceBookingDate ? advanceBookingDate.toLocaleDateString() : ""}
                  style={{
                    flex: 1,
                    border: "none",
                    background: "black",
                    color: "white",
                    outline: "none",
                    textAlign: "left",
                    height: "45px",
                    padding: "8px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={clearDateTime}
          sx={{
            backgroundColor: "rgb(254, 213, 48)",
            borderRadius: "10px",
            color: "#000",
            fontWeight: "550",
            fontFamily: "sans-serif",
            fontSize: "16px",
            padding: "16px",
            mt: 4,
            "&:hover": {
              backgroundColor: "rgb(255, 235, 59)",
            },
          }}
        >
          Clear Date & Time
        </Button>
      </Grid>
      <Grid item xs={12}>
        <div style={{ color: "#fff" }}>
          <p style={{ textAlign: "left" }}>
            <span>{selectedDate ? ` ${moment(selectedDate).format("ddd, D MMM")}, ` : ""}</span>
            <span>{selectedTime ? `${moment(selectedTime).format("hh:mm A")}, ` : ""}</span>
            India Standard Time, based on your location
          </p>
        </div>
      </Grid>
     
    </Grid>
  );
};

export default ResponsiveDatePickers;



